import { constructUrl } from "@fyxer-ai/shared";
import { useState } from "react";
import { Link } from "react-router-dom";
import { z } from "zod";
import { Button } from "@/components/ui/button";
import { useAnalytics } from "@/hooks/useAnalytics";
import { Textarea } from "@/components/ui/textarea";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from "@/components/ui/form";
import { FormUtil } from "./controls/FormUtil";

const OAuthFeedback = ({ connection, error }: { connection: string; error: string | undefined }) => {
  const [showThanks, setShowThanks] = useState(false);
  const { submitSurvey } = useAnalytics();
  const formSchema = z.object({ feedback: z.string().min(2) });

  function onSubmit({ feedback }: z.infer<typeof formSchema>) {
    const SURVEY_ID = "01949848-45b0-0000-84be-4d1423d96509";
    submitSurvey(SURVEY_ID, feedback);
    setShowThanks(true);
  }

  return (
    <div className="mx-auto flex max-w-lg items-center justify-center px-3" style={{ height: "calc(100vh - 48px)" }}>
      <div>
        <Card>
          <CardHeader>
            <CardTitle className="mb-3 leading-8">
              An error occurred when connecting with{" "}
              <span style={{ fontFamily: "inherit" }} className="capitalize">
                {connection?.split?.("-").join(" ")}
              </span>
            </CardTitle>
            <CardDescription>
              To ensure we can deliver the best experience for you with Fyxer AI, please provide any relevant details on
              why the connection didn't work:
            </CardDescription>
          </CardHeader>
          <CardContent>
            {error ? (
              <div className="-mt-3 mb-6 rounded-lg bg-red-200 p-3 text-left font-mono text-xs">{error}</div>
            ) : null}
            {showThanks ? (
              <p className="text-center">Thank you for your feedback!</p>
            ) : (
              <FormUtil
                schema={formSchema}
                defaultValues={{
                  feedback: "",
                }}
                onSubmit={onSubmit}
                render={(form) => (
                  <>
                    <FormField
                      control={form.control}
                      name="feedback"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel className="mb-3 block">Feedback</FormLabel>
                          <FormControl>
                            <Textarea placeholder="Enter your feedback here..." {...field} />
                          </FormControl>
                          <FormMessage className="mt-3" />
                        </FormItem>
                      )}
                    />
                  </>
                )}
                renderSubmitButton={(props) => (
                  <Button {...props} className="mt-3">
                    Send feedback
                  </Button>
                )}
              />
            )}
          </CardContent>
        </Card>

        <div className="mt-6 text-center">
          <Link to={constructUrl({ path: "/org" })}>
            <Button variant="link">Back to Fyxer AI</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OAuthFeedback;
