import { getEnvironment } from "./getEnvironment";
import { Environment } from "@fyxer-ai/shared";
import fyxerLogo from "@/assets/company-logos/fyxer.svg";
import expLogo from "@/assets/company-logos/exp.svg";
import bruntworkLogo from "@/assets/company-logos/bruntwork.png";
import expFavicon from "@/assets/favicons/exp.png";
import bruntworkFavicon from "@/assets/favicons/bruntwork.png";
import { config } from "./config";

export enum WhitelabelProvider {
  FYXER_AI = "FYXER_AI",
  EXP = "EXP",
  BRUNTWORK = "BRUNTWORK",
}

export const getWhitelabelProviderFromPageUrl = () => {
  if (getEnvironment() !== Environment.PROD) return WhitelabelProvider.FYXER_AI;

  const domain = window.location.hostname;

  switch (domain) {
    case "exp.fyxer.com":
    case "expai.exprealty.com":
      return WhitelabelProvider.EXP;
    case "ai.bruntwork.co":
      return WhitelabelProvider.BRUNTWORK;
    default:
      return WhitelabelProvider.FYXER_AI;
  }
};

export const getWhitelabelProviderFromDomain = (domain: string) => {
  switch (domain) {
    case "exprealty.net":
    case "exprealty.com":
      return WhitelabelProvider.EXP;
    case "bruntwork.co":
      return WhitelabelProvider.BRUNTWORK;
    default:
      return WhitelabelProvider.FYXER_AI;
  }
};

export const getCustomDomainFromWhitelabelProvider = (whitelabelProvider: WhitelabelProvider) => {
  switch (whitelabelProvider) {
    case WhitelabelProvider.EXP:
      return "expai.exprealty.com";
    case WhitelabelProvider.BRUNTWORK:
      return "ai.bruntwork.co";
    default:
      return undefined;
  }
};

export const getLogoSrc = () => {
  switch (getWhitelabelProviderFromPageUrl()) {
    case WhitelabelProvider.EXP:
      return expLogo;
    case WhitelabelProvider.BRUNTWORK:
      return bruntworkLogo;
    case WhitelabelProvider.FYXER_AI:
    default:
      return fyxerLogo;
  }
};

export const getMarketingSiteUrl = () => {
  switch (getWhitelabelProviderFromPageUrl()) {
    case WhitelabelProvider.EXP:
      return "https://www.exprealty.com";
    case WhitelabelProvider.BRUNTWORK:
      return "https://www.bruntwork.co";
    default:
      return config().MARKETING_URL;
  }
};

export const getFaviconSrc = () => {
  switch (getWhitelabelProviderFromPageUrl()) {
    case WhitelabelProvider.EXP:
      return expFavicon;
    case WhitelabelProvider.BRUNTWORK:
      return bruntworkFavicon;
    case WhitelabelProvider.FYXER_AI:
    default:
      return "/favicon.ico";
  }
};

export const getCompanyName = () => {
  switch (getWhitelabelProviderFromPageUrl()) {
    case WhitelabelProvider.EXP:
      return "eXp AI";
    case WhitelabelProvider.BRUNTWORK:
      return "Bruntwork";
    case WhitelabelProvider.FYXER_AI:
    default:
      return "Fyxer AI";
  }
};

export const getSsoDetails = () => {
  switch (getWhitelabelProviderFromPageUrl()) {
    case WhitelabelProvider.EXP:
      return {
        providerId: "exprealty.com",
        ssoType: "OIDC",
      };
    case WhitelabelProvider.FYXER_AI:
    default:
      return undefined;
  }
};
