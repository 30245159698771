import { FormUtil } from "@/components/controls/FormUtil";
import { CenterPage } from "@/components/layout/CenterPage";
import { useUser } from "@/context/BaseContext/state/useUser";
import { z } from "zod";
import { parseCustomEmailDomain } from "@fyxer-ai/shared";
import { Navigate, useNavigate } from "react-router-dom";
import { FormFieldUtil } from "@/components/controls/FormFieldUtil";
import { Input } from "@/components/ui/input";
import { useApi } from "@/hooks/useApi";
import { signInWithCustomToken } from "firebase/auth";
import { auth } from "@/lib/firebase/app";

export const AdminLoginPage = () => {
  const { user } = useUser();
  const api = useApi();
  const navigate = useNavigate();
  const emailDomain = parseCustomEmailDomain(user.email);
  if (emailDomain !== "fyxer.com" && user.email !== "david.test-prod@exprealty.net") return <Navigate to="/org" />;

  return (
    <CenterPage>
      <div>
        <h1>Admin login</h1>
        <p>Enter the user id of the account you need to log in as.</p>
        <FormUtil
          className="text-left"
          schema={z.object({
            requestedUserEmail: z.string().email(),
            email: z.string().email(),
            password: z.string().min(1),
          })}
          defaultValues={{
            requestedUserEmail: "",
            email: user.email,
            password: "",
          }}
          render={(form) => (
            <>
              <FormFieldUtil
                control={form.control}
                name="requestedUserEmail"
                render={({ field }) => <Input {...field} />}
              />
              <FormFieldUtil
                control={form.control}
                name="email"
                render={({ field }) => <Input {...field} disabled />}
              />
              <FormFieldUtil
                control={form.control}
                name="password"
                render={({ field }) => <Input {...field} type="password" />}
              />
            </>
          )}
          onSubmit={async (data) => {
            const { requestedUserEmail, email, password } = data;
            const token = await api.adminLogin({ requestedUserEmail, email, password });
            await signInWithCustomToken(auth, token);
            navigate("/");
          }}
        />
      </div>
    </CenterPage>
  );
};
