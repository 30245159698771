import {
  AutoJoinMeetingConfiguration,
  getScopes,
  Membership,
  OauthConnection,
  OauthIntegration,
  OauthProvider,
  OauthServiceType,
  parseCustomEmailDomain,
  organisationConfigDefaults,
} from "@fyxer-ai/shared";
import { useQuery } from "@tanstack/react-query";
import { deleteDoc, QueryDocumentSnapshot, updateDoc } from "firebase/firestore";
import { useLocation } from "react-router-dom";
import { Info, LucideIcon, Pen, Tag, Video, X } from "lucide-react";
import { forwardRef, HTMLAttributes, ReactNode, useRef, useState } from "react";

import zoomLogo from "@/assets/company-logos/zoom.png";
import gmailUrl from "@/assets/company-logos/gmail.png";
import outlookUrl from "@/assets/company-logos/outlook.png";
import googleMeetUrl from "@/assets/company-logos/google-meet.png";

import { ConfirmAlertDialog } from "@/components/ConfirmAlertDialog";
import { CenterPage } from "@/components/layout/CenterPage";
import { Container } from "@/components/layout/Container";
import { Spinner, SpinnerPage } from "@/components/layout/SpinnerPage";
import { Badge } from "@/components/ui/badge";
import { Button, ButtonProps } from "@/components/ui/button";
import { useAuth } from "@/context/BaseContext/state/useAuth";
import { useUser } from "@/context/BaseContext/state/useUser";
import { EventButton, EventName, EventPage, useAnalytics, useLogPageView } from "@/hooks/useAnalytics";
import { useApi } from "@/hooks/useApi";
import { useClickProps } from "@/hooks/useClickProps";
import { useUpdateState } from "@/hooks/useUpdateState";
import { useBreakpoint } from "@/hooks/useWindowSize";
import { unwrap } from "@/lib/firebase/unwrap";
import { LocalStorageBooleanKey, storage } from "@/lib/storage";
import {
  GoogleDisclosure,
  integrationData,
  IntegrationDatum,
} from "@/routes/org/[organisationId]/settings/tabs/integrations/integrationData";
import { useOauthRedirect } from "@/routes/org/[organisationId]/settings/tabs/integrations/useOauthRedirect";
import { useRecallZoomConnectionRedirect } from "@/routes/org/[organisationId]/settings/tabs/integrations/ZoomIntegrationsSection";
import { InvitesForm } from "@/routes/org/[organisationId]/settings/tabs/team/InvitesDialog";
import { PlanTypeSource } from "@/routes/org/[organisationId]/settings/tabs/billing/getPlanType";
import FreeTrialPopup from "@/components/FreeTrialPopup";

import { useOrganisation } from "../state/useOrganisation";
import { cn } from "@/lib/utils";
import { getCompanyName, getWhitelabelProviderFromPageUrl, WhitelabelProvider } from "@/lib/getWhitelabelProvider";
import { Switch } from "@/components/ui/switch";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";

const ReconnectRow = ({ connection }: { connection: QueryDocumentSnapshot<OauthConnection> }) => {
  const { metadata, integration, organisationId } = connection.data();
  const email = metadata.email as string | undefined;
  const { title, logoUrl } = integrationData.find((datum) => datum.integration === integration) as IntegrationDatum;
  const { handleOauthRedirect } = useOauthRedirect();
  const refreshButtonProps = useClickProps({
    onClick: () => handleOauthRedirect({ integration, organisationId, type: "integration" }),
    buttonText: "Refresh",
  });
  return (
    <div className="flex items-center gap-x-4">
      <img alt={title} src={logoUrl} className="h-8 w-8 rounded-sm" />
      <h4>{title}</h4>
      {email ? <Badge variant="secondary">{email}</Badge> : null}
      <div className="flex-grow" />
      <Button {...refreshButtonProps} />
      <ConfirmAlertDialog
        title="Delete"
        warningText="If you delete the connection, you'll lose all associated data."
        fn={() => deleteDoc(connection.ref)}
      />
    </div>
  );
};

const OutOfDateConnectionsPage = ({
  outOfDateConnections,
}: {
  outOfDateConnections: QueryDocumentSnapshot<OauthConnection>[];
}) => {
  return (
    <Container className="space-y-4 py-12">
      <div className="space-y-2">
        <h1>Refresh your integrations</h1>
        <p className="text-slate-500">
          Occasionally we release updates that require us to refresh our access to your connections with{" "}
          {getCompanyName()}. Please refresh the following connections to return to the main dashboard.
        </p>
      </div>

      <div className="space-y-2">
        {outOfDateConnections.map((connection) => (
          <ReconnectRow key={connection.id} connection={connection} />
        ))}
      </div>
    </Container>
  );
};

type NoConnectionsPageState = {
  autoJoinMeetingConfiguration: AutoJoinMeetingConfiguration;
  areLabelsEnabled: boolean;
  areDraftsEnabled: boolean;
  redirectingIntegration: OauthIntegration | undefined;
};

const ConnectionButton = forwardRef<
  HTMLButtonElement,
  ButtonProps & {
    integration: OauthIntegration;
    redirectingIntegration: OauthIntegration | undefined;
    connectIntegration: (integration: OauthIntegration) => Promise<void>;
  }
>(({ integration, redirectingIntegration, connectIntegration, ...props }, ref) => {
  const { title, logoUrl } = integrationData.find((datum) => datum.integration === integration) as IntegrationDatum;

  return (
    <Button
      variant="outline"
      size="lg"
      className="w-full flex-grow drop-shadow-xl"
      {...props}
      ref={ref}
      onClick={() => connectIntegration(integration)}
      disabled={!!redirectingIntegration}
    >
      {redirectingIntegration === integration ? (
        <Spinner className="m-0 flex-grow-0" />
      ) : (
        <img src={logoUrl} className="h-4 w-4" />
      )}
      Connect{redirectingIntegration === integration ? "ing" : ""} {title}
    </Button>
  );
});

const CircleIcon = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement> & { Icon: LucideIcon }>(
  ({ Icon, className, ...props }, ref) => (
    <div
      className={cn("flex h-12 w-12 items-center justify-center rounded-full bg-purple-100", className)}
      {...props}
      ref={ref}
    >
      <Icon className="stroke-purple-500" size={24} />
    </div>
  ),
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NoEmailConnectionPage = ({ userMembership }: { userMembership: QueryDocumentSnapshot<Membership> }) => {
  const { organisation } = useOrganisation();
  const { defaultAutoJoinMeetingConfiguration } = organisation;
  const [state, updateState] = useUpdateState<NoConnectionsPageState>({
    autoJoinMeetingConfiguration: defaultAutoJoinMeetingConfiguration || AutoJoinMeetingConfiguration.ONLY_MINE,
    areLabelsEnabled: true,
    areDraftsEnabled: true,
    redirectingIntegration: undefined,
  });
  const [showCalendarOptions, setShowCalendarOptions] = useState(true);
  const { handleOauthRedirect } = useOauthRedirect();
  const breakpoint = useBreakpoint();
  const buttonsRef = useRef<HTMLDivElement>(null);

  useLogPageView(EventPage.NO_EMAIL_CONNECTION);

  const connectIntegration = async (integration: OauthIntegration) => {
    const { organisationId } = userMembership.data();
    updateState({ redirectingIntegration: integration });
    await updateDoc(userMembership.ref, {
      autoJoinMeetingConfiguration: state.autoJoinMeetingConfiguration,
      areDraftsEnabled: state.areDraftsEnabled,
      areLabelsEnabled: state.areLabelsEnabled,
    });
    const isGoogle = [OauthIntegration.GMAIL, OauthIntegration.GOOGLE_CALENDAR].includes(integration);
    const rest = isGoogle
      ? { type: "provider" as const, provider: OauthProvider.GOOGLE }
      : { type: "integration" as const, integration };
    await handleOauthRedirect({ organisationId, ...rest });
    updateState({ redirectingIntegration: undefined });
  };

  return (
    <Container className={`max-w-lg py-12 max-sm:pt-4`}>
      <h1 className={`mb-6 text-center text-3xl max-sm:text-2xl`}>Connect your email</h1>

      <div>
        <p className="text-center">Personalise how your AI Executive Assistant will work for you:</p>

        <div className="mb-6 mt-3 flex items-center gap-x-3 rounded-lg bg-gray-100 p-3">
          <Info size={18} className="flex-shrink-0" />
          <p className="text-xs">
            We never send email on your behalf. We leave drafts for you to edit and send. If it doesn't work out with
            us, we'll leave your inbox as we found it.
          </p>
        </div>
      </div>

      <div
        className={"mt-0"}
        style={{
          marginBottom: breakpoint === "xs" && buttonsRef.current ? buttonsRef.current.offsetHeight : undefined,
        }}
      >
        <div>
          <Card className="mb-3">
            <CardHeader className="p-3">
              <div className="flex items-center gap-x-3">
                <div className="flex-grow">
                  <div className="mb-1 flex items-center gap-x-1.5">
                    <p className="mr-1 text-sm font-semibold">Categorise inbox</p>
                    <img width={30} src={gmailUrl} />
                    <img width={30} src={outlookUrl} />
                  </div>
                  <p className="text-xs text-gray-500">
                    Organise emails into actionable labels, making it easy to spot what needs your attention.
                  </p>
                </div>
                <Switch
                  checked={state.areLabelsEnabled}
                  onCheckedChange={(checked) => updateState({ areLabelsEnabled: checked })}
                  className="h-[22px]"
                />
              </div>
            </CardHeader>
          </Card>
          <Card className="mb-3">
            <CardHeader className="p-3">
              <div className="flex items-center gap-x-3">
                <div className="flex-grow">
                  <div className="mb-1 flex items-center gap-x-1.5">
                    <p className="mr-1 text-sm font-semibold">Draft replies</p>
                    <img width={30} src={gmailUrl} />
                    <img width={30} src={outlookUrl} />
                  </div>
                  <p className="text-xs text-gray-500">
                    Draft replies to emails that require a response using my tone and language.
                  </p>
                </div>
                <Switch
                  checked={state.areDraftsEnabled}
                  onCheckedChange={(checked) => updateState({ areDraftsEnabled: checked })}
                  className="h-[22px]"
                />
              </div>
            </CardHeader>
          </Card>
        </div>

        <div>
          <Card className="mb-6">
            <CardHeader className="p-3">
              <div className="flex items-center gap-x-3">
                <div className="flex-grow">
                  <div className="mb-1 flex items-center gap-x-1.5">
                    <p className="mr-1 text-sm font-semibold">Summarise meetings</p>
                    <img width={30} src={googleMeetUrl} />
                    <img width={30} src={outlookUrl} />
                    <img width={20} src={zoomLogo} />
                  </div>
                  <p className="text-xs text-gray-500">
                    Let the Fyxer AI Notetaker join your meetings, transcribe, and draft follow-up emails for you.
                  </p>
                </div>
                <Switch
                  className="h-[22px]"
                  checked={showCalendarOptions}
                  onCheckedChange={(checked) => {
                    if (checked === false) {
                      updateState({ autoJoinMeetingConfiguration: AutoJoinMeetingConfiguration.NEVER });
                    } else if (checked && state.autoJoinMeetingConfiguration === AutoJoinMeetingConfiguration.NEVER) {
                      updateState({ autoJoinMeetingConfiguration: AutoJoinMeetingConfiguration.ONLY_MINE });
                    }

                    setShowCalendarOptions(checked);
                  }}
                />
              </div>
            </CardHeader>
            {showCalendarOptions && (
              <CardContent className="p-3 pt-0">
                <div>
                  <p className="mb-1.5 text-xs font-medium text-gray-700">Let the AI notetaker summarise:</p>

                  <Tabs
                    activationMode="manual"
                    value={state.autoJoinMeetingConfiguration}
                    onValueChange={(value) => {
                      updateState({
                        autoJoinMeetingConfiguration: value as AutoJoinMeetingConfiguration,
                      });
                    }}
                  >
                    <TabsList className="h-10 w-full flex-grow border-0">
                      <TabsTrigger
                        className="flex-grow text-xs data-[state=active]:border-0 data-[state=active]:bg-gray-200"
                        value={AutoJoinMeetingConfiguration.ONLY_MINE}
                      >
                        My meetings
                      </TabsTrigger>
                      <TabsTrigger
                        className="flex-grow text-xs data-[state=active]:border-0 data-[state=active]:bg-gray-200"
                        value={AutoJoinMeetingConfiguration.ALWAYS}
                      >
                        All meetings
                      </TabsTrigger>
                      <TabsTrigger
                        className="flex-grow text-xs data-[state=active]:border-0 data-[state=active]:bg-gray-200"
                        value={AutoJoinMeetingConfiguration.ONLY_EXTERNAL}
                      >
                        External meetings
                      </TabsTrigger>
                    </TabsList>
                  </Tabs>
                </div>
              </CardContent>
            )}
          </Card>
        </div>
      </div>

      <div
        className="space-y-4 max-sm:fixed max-sm:bottom-0 max-sm:left-0 max-sm:w-full max-sm:border-t max-sm:border-slate-100 max-sm:bg-white max-sm:p-4"
        ref={buttonsRef}
      >
        <div className="max-sm:space-y-2 sm:flex sm:items-center sm:gap-x-4">
          <ConnectionButton
            {...{
              integration: OauthIntegration.GMAIL,
              connectIntegration,
              redirectingIntegration: state.redirectingIntegration,
            }}
          />
          <ConnectionButton
            {...{
              integration: OauthIntegration.MICROSOFT_OUTLOOK_EMAIL,
              connectIntegration,
              redirectingIntegration: state.redirectingIntegration,
            }}
          />
        </div>

        <GoogleDisclosure />
      </div>
    </Container>
  );
};

const NoCalendarConnectionPage = ({ organisationId }: { organisationId: string }) => {
  useLogPageView(EventPage.NO_CALENDAR_CONNECTION);

  const [state, updateState] = useUpdateState<{ redirectingIntegration: OauthIntegration | undefined }>({
    redirectingIntegration: undefined,
  });
  const { handleOauthRedirect } = useOauthRedirect();
  const buttonsRef = useRef<HTMLDivElement>(null);

  const connectIntegration = async (integration: OauthIntegration) => {
    updateState({ redirectingIntegration: integration });
    const isGoogle = [OauthIntegration.GMAIL, OauthIntegration.GOOGLE_CALENDAR].includes(integration);
    const rest = isGoogle
      ? { type: "provider" as const, provider: OauthProvider.GOOGLE }
      : { type: "integration" as const, integration };
    await handleOauthRedirect({ organisationId, ...rest });
    updateState({ redirectingIntegration: undefined });
  };

  return (
    <Container className="max-w-2xl space-y-12 py-12">
      <h1 className="text-3xl">Connect your calendar</h1>

      <div className="space-y-4">
        <p>{getCompanyName()} works by connecting to your email and calendar. Once we're connected, we'll:</p>
        <div className="flex items-center gap-x-4">
          <CircleIcon Icon={Tag} className="flex-shrink-0" />
          <p>Screen low priority emails from your inbox and highlight what's important</p>
        </div>
        <div className="flex items-center gap-x-4">
          <CircleIcon Icon={Pen} className="flex-shrink-0" />
          <p>Leave draft replies in email threads you need to reply to or follow up on</p>
        </div>
        <div className="flex items-center gap-x-4">
          <CircleIcon Icon={Video} className="flex-shrink-0" />
          <p>Join meetings and recording, transcribe and take notes</p>
        </div>
        <p>We never send email on your behalf. We leave drafts for you to edit and send.</p>
        <p>If it doesn't work out with us, we'll leave your inbox as we found it.</p>
      </div>

      <div
        className="space-y-4 max-sm:fixed max-sm:bottom-0 max-sm:left-0 max-sm:w-full max-sm:border-t max-sm:border-slate-100 max-sm:bg-white max-sm:p-4"
        ref={buttonsRef}
      >
        <div className="max-sm:space-y-2 sm:flex sm:items-center sm:gap-x-4">
          <ConnectionButton
            {...{
              integration: OauthIntegration.GOOGLE_CALENDAR,
              connectIntegration,
              redirectingIntegration: state.redirectingIntegration,
            }}
          />
          <ConnectionButton
            {...{
              integration: OauthIntegration.MICROSOFT_OUTLOOK_CALENDAR,
              connectIntegration,
              redirectingIntegration: state.redirectingIntegration,
            }}
          />
        </div>

        <GoogleDisclosure />
      </div>
    </Container>
  );
};

const NoZoomConnectionPage = ({ organisationId, markSeen }: { organisationId: string; markSeen: () => void }) => {
  useLogPageView(EventPage.NO_ZOOM_CONNECTION);

  const { redirectToAuthUrl } = useRecallZoomConnectionRedirect();

  const handleSkip = () => {
    storage.local.boolean(LocalStorageBooleanKey.WAS_ZOOM_CONNECTION_SKIPPED).set(true);
    markSeen();
  };

  const redirectToZoomClickProps = useClickProps({
    onClick: () => redirectToAuthUrl(organisationId),
    buttonText: "Connect Zoom",
  });

  return (
    <CenterPage className="space-y-8 max-sm:space-y-4">
      <img src={zoomLogo} className="m-auto h-8 w-8 rounded" />
      <h1 className="text-center text-3xl max-sm:text-xl">Do you use Zoom?</h1>
      <p className="text-center">
        Connect your zoom account with {getCompanyName()} so our Notetaker can take the minutes from your video meetings
      </p>
      <div className="space-y-2">
        <Button {...redirectToZoomClickProps} className="w-full" />
        <Button variant="ghost" onClick={handleSkip} className="w-full">
          Skip for now
        </Button>
      </div>
    </CenterPage>
  );
};

export const TeamInvitePage = ({ markSeen }: { markSeen: (() => void) | undefined }) => {
  useLogPageView(EventPage.ADD_TEAM);
  const { organisationId } = useOrganisation();
  const { logEvent } = useAnalytics();

  const api = useApi();
  const [state, updateState] = useUpdateState<{ invitees: string[] | undefined }>({ invitees: undefined });
  const recommendedInvitees = useQuery({
    queryKey: ["recommendedInvitees", organisationId],
    queryFn: async () => {
      const emails = await api.organisations.id(organisationId).invites.recommend();
      updateState({ invitees: emails });
      logEvent(EventName.INVITE_RECOMMENDATIONS_LOADED, { recommendations: emails.length });
      return emails;
    },
    refetchOnWindowFocus: false,
  });

  const handleNext = () => {
    storage.local.boolean(LocalStorageBooleanKey.WAS_TEAMS_SIGNUP_SKIPPED).set(true);
    markSeen?.();
  };

  if (recommendedInvitees.isLoading) return <SpinnerPage />;

  return (
    <CenterPage className="max-w-[540px] space-y-4">
      <h1 className="text-center text-3xl max-sm:text-xl">Give your teammates a free trial</h1>
      {state.invitees !== undefined && (
        <InvitesForm initialInviteEmails={state.invitees} showRole={false} afterSubmit={() => handleNext()} />
      )}
      {markSeen && (
        <Button variant="ghost" onClick={handleNext} className="w-full">
          Skip for now
        </Button>
      )}
    </CenterPage>
  );
};

const OnboardingIntegrationImage = ({ src, alt }: { src: string; alt: string }) => (
  <img src={src} alt={alt} className="h-10 w-10 rounded-lg border border-slate-100" />
);

export const OnboardingIntegrationsRack = ({
  integrations,
  text = "Pairs with:",
}: {
  integrations: { src: string; alt: string }[];
  text?: string;
}) => (
  <div className="flex items-center gap-x-4">
    <p>{text}</p>
    <div className="flex gap-x-2">
      {integrations.map(({ src, alt }) => (
        <OnboardingIntegrationImage src={src} alt={alt} key={src} />
      ))}
    </div>
  </div>
);

export const ConnectionsGuard = ({ children }: { children: ReactNode }) => {
  const [showFreeTrialPopup, setShowFreeTrialPopup] = useState(true);
  const { userMembership, planData, connections, organisationId, recallZoomConnections, isAdmin, organisation } =
    useOrganisation();
  const { user } = useUser();
  const { userId } = useAuth();
  const location = useLocation();
  const { logButtonPress } = useAnalytics();
  const { planType, planTypeSource } = planData;
  const canOnlyAdminsInviteUsers =
    organisation.canOnlyAdminsInviteUsers ?? organisationConfigDefaults.canOnlyAdminsInviteUsers;
  const canInviteUsers = isAdmin || !canOnlyAdminsInviteUsers;

  const connectionsThatCantBeOutOfDate = connections.filter(
    unwrap((connection) => !connection.isOrgLevel || connection.userId === userId),
  );
  const outOfDateConnections = connectionsThatCantBeOutOfDate.filter(
    unwrap((connection) => {
      const { approvedScopes, integration } = connection;
      const requestedScopes = getScopes({ integration, mode: "validate" });
      const hasAllScopes = requestedScopes.every((scope) => approvedScopes.includes(scope));
      return !hasAllScopes;
    }),
  );

  const [state, updateState] = useUpdateState({
    hasSeenZoomConnectionPage: storage.local.boolean(LocalStorageBooleanKey.WAS_ZOOM_CONNECTION_SKIPPED).get(),
    hasSeenTeamsSignupPage: storage.local.boolean(LocalStorageBooleanKey.WAS_TEAMS_SIGNUP_SKIPPED).get(),
  });

  const emailConnections = connections.filter(
    unwrap((connection) => connection.serviceType === OauthServiceType.EMAIL),
  );

  const calendarConnections = connections.filter(
    unwrap((connection) => connection.serviceType === OauthServiceType.CALENDAR),
  );

  const hasSeenFreeTrialPopup = storage.local.boolean(LocalStorageBooleanKey.SEEN_CC_UPFRONT_OFFER).get();

  // don't show guard when processing stripe payment
  if (location.pathname.includes("/checkout-session")) {
    return children;
  }

  if (outOfDateConnections.length > 0) {
    return <OutOfDateConnectionsPage outOfDateConnections={outOfDateConnections} />;
  }

  if (planType !== "FREE" && (emailConnections.length === 0 || calendarConnections.length === 0)) {
    if (emailConnections.length === 0) {
      return <NoEmailConnectionPage userMembership={userMembership} />;
    } else if (calendarConnections.length === 0) {
      return <NoCalendarConnectionPage organisationId={organisationId} />;
    }
  }

  const isOnFreeTrialOrFreePlan = planType === "FREE" || planTypeSource === PlanTypeSource.FREE_TRIAL;
  const hasCustomDomain = Boolean(parseCustomEmailDomain(user.email));

  if (isOnFreeTrialOrFreePlan && !hasSeenFreeTrialPopup && showFreeTrialPopup) {
    const onSkip = () => {
      setShowFreeTrialPopup(false);
      logButtonPress(EventButton.SKIP_FREE_TRIAL_POPUP);
      storage.local.boolean(LocalStorageBooleanKey.SEEN_CC_UPFRONT_OFFER).set(true);
    };

    return (
      <div className="fixed bottom-0 left-0 right-0 top-0 z-50 flex items-center justify-center bg-white">
        <X size={32} className="absolute right-4 top-4 cursor-pointer" onClick={onSkip} />
        <div>
          <FreeTrialPopup onSkip={onSkip} showSkip />
        </div>
      </div>
    );
  }

  if (
    recallZoomConnections.length === 0 &&
    !state.hasSeenZoomConnectionPage &&
    planType !== "FREE" &&
    getWhitelabelProviderFromPageUrl() === WhitelabelProvider.FYXER_AI
  ) {
    return (
      <NoZoomConnectionPage
        organisationId={organisationId}
        markSeen={() => updateState({ hasSeenZoomConnectionPage: true })}
      />
    );
  }

  if (hasCustomDomain && !state.hasSeenTeamsSignupPage && canInviteUsers) {
    return <TeamInvitePage markSeen={() => updateState({ hasSeenTeamsSignupPage: true })} />;
  }

  return children;
};
