import { Button } from "@/components/ui/button";
import { sort } from "@fyxer-ai/shared";

const DateHeading = ({ date }: { date: string }) => (
  <h3 className="mb-4 text-base font-medium text-gray-800">{date}</h3>
);

const SlotGrid = ({
  slots,
  onSelect,
}: {
  slots: { time: string; slot: { startsAt: Date; endsAt: Date; usersTimeZone: string } }[];
  onSelect: (slot: { startsAt: Date; endsAt: Date; usersTimeZone: string }) => void;
}) => (
  <div className="grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3">
    {slots.map(({ time, slot }) => (
      <Button
        key={time}
        onClick={() => onSelect(slot)}
        className="w-full rounded-md bg-gray-100 text-gray-700 hover:bg-gray-200"
      >
        {time}
      </Button>
    ))}
  </div>
);

export const SlotSelectionPanel = ({
  suggestedSlots,
  schedulingTimeZone,
  onSlotSelect,
}: {
  suggestedSlots: {
    startsAt: Date;
    endsAt: Date;
    usersTimeZone: string;
  }[];
  schedulingTimeZone: string;
  onSlotSelect: (slot: { startsAt: Date; endsAt: Date; usersTimeZone: string }) => void;
}) => {
  const sortedSlots = sort(suggestedSlots, (slot) => slot.startsAt, "asc");

  // Using "en-CA" gives a "yyyy-mm-dd" format which is ideal for sorting.
  const slotsByDate = sortedSlots.reduce(
    (
      acc: Record<
        string,
        {
          displayDate: string;
          slots: { time: string; slot: { startsAt: Date; endsAt: Date; usersTimeZone: string } }[];
        }
      >,
      slot,
    ) => {
      const dateKey = slot.startsAt.toLocaleDateString("en-CA", {
        timeZone: schedulingTimeZone,
      });

      const displayDate = new Intl.DateTimeFormat("en-US", {
        weekday: "long",
        month: "long",
        day: "numeric",
        timeZone: schedulingTimeZone,
      }).format(slot.startsAt);

      const time = new Intl.DateTimeFormat("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
        timeZone: schedulingTimeZone,
      }).format(slot.startsAt);

      if (!acc[dateKey]) {
        acc[dateKey] = { displayDate, slots: [] };
      }
      acc[dateKey].slots.push({ time, slot });

      return acc;
    },
    {},
  );

  const sortedDateKeys = sort(Object.keys(slotsByDate), (dateKey) => dateKey, "asc");

  return (
    <div>
      {sortedDateKeys.map((dateKey) => (
        <div key={dateKey} className="mb-6">
          <DateHeading date={slotsByDate[dateKey].displayDate} />
          <SlotGrid slots={slotsByDate[dateKey].slots} onSelect={onSlotSelect} />
        </div>
      ))}
    </div>
  );
};
