import { EventPage, useLogPageView } from "@/hooks/useAnalytics";
import { useQueryParams } from "@/hooks/useQueryParams";

import { AuthPageShell } from "./AuthPageShell";
import { AuthType } from "./types/AuthType";

export const SignUpPage = () => {
  const { return_path, from_marketing } = useQueryParams();
  const isFromMarketing = from_marketing === "yes";
  const returnPath = return_path ?? "/org";
  useLogPageView(EventPage.SIGN_UP, { returnPath, isFromMarketing });

  return <AuthPageShell outcomeExp={false} authType={AuthType.SIGN_UP} />;
};
