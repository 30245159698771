import { Link } from "react-router-dom";
import jasonNvidia from "@/assets/people/jason-nvidia.png";
import { Logo } from "@/components/layout/TopBar";
import { getMarketingSiteUrl, getWhitelabelProviderFromPageUrl, WhitelabelProvider } from "@/lib/getWhitelabelProvider";

import { AuthForm } from "./AuthForm";
import { AuthType } from "./types/AuthType";

export const AuthPageShell = ({ authType, outcomeExp }: { authType: AuthType; outcomeExp?: boolean }) => {
  return (
    <div className={`flex ${outcomeExp ? "h-full" : "h-screen"} flex-col`}>
      {!outcomeExp && (
        <div className="border border-slate-100 bg-white px-4 py-2">
          <Link to={getMarketingSiteUrl()} target="_blank" rel="noreferrer">
            <Logo />
          </Link>
        </div>
      )}
      <div className={`flex-grow max-sm:mt-2 max-sm:p-4 sm:flex sm:items-center sm:justify-center`}>
        <div>
          <AuthForm authType={authType} />
          <div className="mt-12">
            <div className="flex justify-center">
              {getWhitelabelProviderFromPageUrl() === WhitelabelProvider.FYXER_AI && (
                <div className="max-w-md space-y-2 rounded-3xl border border-black bg-slate-50 px-8 py-6">
                  <img className="m-auto h-8 w-8 rounded-full" src={jasonNvidia} />
                  <p className="text-center text-xl font-bold">
                    ”Fyxer.ai is a masterpiece. They’ve built an incredibly useful product that just might fix email
                    forever.”
                  </p>
                  <p className="text-center">Jason La Barbera</p>
                  <p className="text-center">AI Ambassador @ NVIDIA</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
