import { BillingCycle, constructUrl, SubscriptionPlanType } from "@fyxer-ai/shared";
import { ChevronLeft } from "lucide-react";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { z } from "zod";

import { Tabs, TabsList, TabsTrigger } from "@radix-ui/react-tabs";
import { FormFieldUtil } from "@/components/controls/FormFieldUtil";
import { FormUtil } from "@/components/controls/FormUtil";
import { SelectUtil } from "@/components/controls/SelectUtil";
import { Button } from "@/components/ui/button";
import { NumberInput } from "@/components/ui/input";
import { Table, TableCell, TableRow } from "@/components/ui/table";
import { useOrganisation } from "@/context/OrganisationContext/state/useOrganisation";
import { EventForm, EventPage, useAnalytics, useFeatureFlag, useLogPageView } from "@/hooks/useAnalytics";
import { useApi } from "@/hooks/useApi";
import { useQueryParams } from "@/hooks/useQueryParams";
import { redirectExternally } from "@/lib/redirectExternally";
import { getDisplayPrice, getPriceCents, newData } from "@/routes/org/PricingView";
import { getCompanyName } from "@/lib/getWhitelabelProvider";

const selfServePlanTypes = [SubscriptionPlanType.STANDARD, SubscriptionPlanType.PRO] as const;

const paramsSchema = z.object({
  billingCycle: z.nativeEnum(BillingCycle),
  seatCount: z.string(),
  planType: z.enum(selfServePlanTypes),
});

const Toggle = ({
  items,
  value,
  onChange,
  className,
}: {
  items: Array<{ value: string; label: string }>;
  value: string;
  onChange: (value: string) => void;
  className?: string;
}) => {
  return (
    <Tabs onValueChange={onChange} className={className}>
      <TabsList className="grid w-full grid-cols-2 gap-x-1.5 rounded border border-gray-200 bg-white p-1.5">
        {items.map((item) => (
          <TabsTrigger
            className={`rounded transition ${item.value === value ? "bg-purple-500 text-white" : "bg-white"} border border-gray-200 py-2 text-sm`}
            value={item.value}
          >
            {item.label}
          </TabsTrigger>
        ))}
      </TabsList>
    </Tabs>
  );
};

export const CreateSubscriptionPage = () => {
  useLogPageView(EventPage.CREATE_SUBSCRIPTION);
  const { logFormSubmit } = useAnalytics();
  const { memberships, organisationId } = useOrganisation();
  const { billingCycle, seatCount: seatCountString, planType } = paramsSchema.parse(useQueryParams());
  const seatCount = parseInt(seatCountString, 10);
  const startSubscriptionExp = useFeatureFlag("start-subscription");
  const api = useApi();

  const membershipCount = useMemo(() => memberships.length, [memberships]);

  if (startSubscriptionExp.value() === "test") {
    return (
      <div className="min-h-screen px-8 py-10 sm:min-h-full">
        <div className="mx-auto max-w-md">
          <Link
            className="mb-10 inline-block"
            to={constructUrl({
              path: `/org/${organisationId}/settings`,
              hash: {
                tab: "billing",
              },
            })}
          >
            <Button variant="link" className="pl-0">
              <ChevronLeft />
              Back
            </Button>
          </Link>
          <h2 className="mb-10 text-left text-4xl">Start a subscription</h2>
          <FormUtil
            schema={z.object({
              billingCycle: z.nativeEnum(BillingCycle),
              planType: z.enum(selfServePlanTypes),
              seatCount: z.number().int().gte(Math.max(1, membershipCount)),
            })}
            defaultValues={{
              seatCount: Math.max(seatCount, memberships.length),
              planType,
              billingCycle,
            }}
            submitTitle="Proceed to payment"
            render={(form) => {
              const billingCycle = form.watch("billingCycle");
              const seatCount = form.watch("seatCount");
              const planTypeForm = form.watch("planType");
              const priceMonthlyCents =
                newData.find((datum) => datum.planType === planTypeForm)?.priceMonthlyCents ?? 3000;

              const pricePerSeatMonthlyBasis = getPriceCents({ priceMonthlyCents, billingCycle: BillingCycle.MONTHLY });

              const pricePerSeatPerMonth = getPriceCents({ priceMonthlyCents, billingCycle });

              return (
                <>
                  <FormFieldUtil
                    render={({ field }) => (
                      <Toggle
                        className="mt-2"
                        items={Object.values(BillingCycle).map((cycle) => ({
                          value: cycle,
                          label: cycle === BillingCycle.MONTHLY ? "Monthly" : "Annually",
                        }))}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                    control={form.control}
                    name="billingCycle"
                  />
                  <FormFieldUtil
                    render={({ field }) => (
                      <div className="flex items-center gap-x-3">
                        <NumberInput {...field} min={Math.max(1, memberships.length)} />
                        <Button onClick={() => field.onChange(field.value + 1)} type="button" variant="outline">
                          +
                        </Button>
                        <Button
                          disabled={field.value <= Math.max(1, memberships.length)}
                          onClick={() => field.onChange(field.value - 1)}
                          type="button"
                          variant="outline"
                        >
                          -
                        </Button>
                      </div>
                    )}
                    control={form.control}
                    name="seatCount"
                    description={`This can't be less than the number of teammates you have on ${getCompanyName()}, which is ${memberships.length}.`}
                  />

                  <div className="pb-3 pt-3">
                    <p className="mb-3 text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                      Plan summary
                    </p>

                    <Table>
                      <TableRow>
                        <TableCell>Seats</TableCell>
                        <TableCell className="text-right">{seatCount}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Cost per seat</TableCell>
                        <TableCell className="text-right">
                          {billingCycle === BillingCycle.ANNUALLY ? (
                            <span className="mr-2 text-gray-500 line-through">
                              {getDisplayPrice(pricePerSeatMonthlyBasis, true)}
                            </span>
                          ) : null}
                          {seatCount ? getDisplayPrice(pricePerSeatPerMonth, true) + " / month" : "-"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Total cost</TableCell>
                        <TableCell className="text-right">
                          {(() => {
                            if (seatCount) {
                              const price = getDisplayPrice(
                                pricePerSeatPerMonth * seatCount * (billingCycle === BillingCycle.MONTHLY ? 1 : 12),
                                true,
                              );
                              const interval = billingCycle === BillingCycle.MONTHLY ? "month" : "year";

                              return price + " / " + interval;
                            }
                            return "-";
                          })()}
                        </TableCell>
                      </TableRow>
                      {billingCycle === BillingCycle.ANNUALLY && seatCount > 0 ? (
                        <TableRow className="bg-teal-50">
                          <TableCell className="font-bold text-teal-800">Total savings</TableCell>
                          <TableCell className="text-right font-bold text-teal-800">
                            {getDisplayPrice(
                              pricePerSeatMonthlyBasis * seatCount * 12 - pricePerSeatPerMonth * seatCount * 12,
                              true,
                            ) + " / year"}
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </Table>
                  </div>
                </>
              );
            }}
            onSubmit={async (data) => {
              logFormSubmit(EventForm.START_SUBSCRIPTION, data);
              const checkoutSessionUrl = await api.organisations.id(organisationId).checkoutSessions.create(data);
              redirectExternally(checkoutSessionUrl);
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="p-8">
      <Link
        to={constructUrl({
          path: `/org/${organisationId}/settings`,
          hash: {
            tab: "billing",
          },
        })}
      >
        <Button variant="secondary">
          <ChevronLeft />
          Back
        </Button>
      </Link>

      <div className="m-auto max-w-md space-y-4 py-20">
        <h2>Start a subscription</h2>
        <FormUtil
          schema={z.object({
            billingCycle: z.nativeEnum(BillingCycle),
            planType: z.enum(selfServePlanTypes),
            seatCount: z.number().int().gte(Math.max(1, membershipCount)),
          })}
          defaultValues={{
            seatCount: Math.max(seatCount, memberships.length),
            planType,
            billingCycle,
          }}
          submitTitle="Proceed to payment"
          render={(form) => {
            const billingCycle = form.watch("billingCycle");
            const seatCount = form.watch("seatCount");
            const planTypeForm = form.watch("planType");
            const priceMonthlyCents =
              newData.find((datum) => datum.planType === planTypeForm)?.priceMonthlyCents ?? 3000;

            const pricePerSeatPerMonth = getPriceCents({ priceMonthlyCents, billingCycle });

            return (
              <>
                <FormFieldUtil
                  render={({ field }) => (
                    <SelectUtil {...field} items={[SubscriptionPlanType.STANDARD, SubscriptionPlanType.PRO]} />
                  )}
                  control={form.control}
                  name="planType"
                />
                <FormFieldUtil
                  render={({ field }) => <SelectUtil {...field} items={Object.values(BillingCycle)} />}
                  control={form.control}
                  name="billingCycle"
                />
                <FormFieldUtil
                  render={({ field }) => <NumberInput {...field} />}
                  control={form.control}
                  name="seatCount"
                  description={`This can't be less than the number of teammates you have on ${getCompanyName()}, which is ${memberships.length}.`}
                />

                <Table>
                  <TableRow>
                    <TableCell>Cost per seat</TableCell>
                    <TableCell className="text-right">
                      {seatCount ? getDisplayPrice(pricePerSeatPerMonth, true) + " / month" : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total cost</TableCell>
                    <TableCell className="text-right">
                      {seatCount
                        ? getDisplayPrice(
                            pricePerSeatPerMonth * seatCount * (billingCycle === BillingCycle.MONTHLY ? 1 : 12),
                            true,
                          ) +
                          " / " +
                          (billingCycle === BillingCycle.MONTHLY ? "month" : "year")
                        : "-"}
                    </TableCell>
                  </TableRow>
                </Table>
              </>
            );
          }}
          onSubmit={async (data) => {
            logFormSubmit(EventForm.START_SUBSCRIPTION, data);
            const checkoutSessionUrl = await api.organisations.id(organisationId).checkoutSessions.create(data);
            redirectExternally(checkoutSessionUrl);
          }}
        />
      </div>
    </div>
  );
};
