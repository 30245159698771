import { createBrowserRouter, Outlet } from "react-router-dom";

import { AuthGuard } from "@/context/BaseContext/guards/AuthGuard.tsx";
import { MembershipsGuard } from "@/context/BaseContext/guards/MembershipsGuard.tsx";
import { UnauthGuard } from "@/context/BaseContext/guards/UnauthGuard.tsx";
import { UserGuard } from "@/context/BaseContext/guards/UserGuard.tsx";

import { SideBar } from "./components/layout/SideBar.tsx";
import { CalendarEventsProvider } from "./context/CalendarEventsContext/CalendarEventsContext.tsx";
import { CalendarEventsGuard } from "./context/CalendarEventsContext/guards/CalendarEventsGuard.tsx";
import { CallRecordingProvider } from "./context/CallRecordingContext/CallRecordingContext.tsx";
import { CallRecordingGuard } from "./context/CallRecordingContext/guards/CallRecordingGuard.tsx";
import { CallRecordingsProvider } from "./context/CallRecordingsContext/CallRecordingsContext.tsx";
import { CallRecordingsGuard } from "./context/CallRecordingsContext/guards/CallRecordingsGuard.tsx";
import { CallRecordingSnippetProvider } from "./context/CallRecordingSnippetContext/CallRecordingContext.tsx";
import { CallRecordingSnippetGuard } from "./context/CallRecordingSnippetContext/guards/CallRecordingSnippetGuard.tsx";
import { ConnectionsGuard } from "./context/OrganisationContext/guards/ConnectionsGuard.tsx";
import { OrganisationGuard } from "./context/OrganisationContext/guards/OrganisationGuard.tsx";
import { EmailLinkPage } from "./routes/auth/email-link.tsx";
import { EmailLinkSentPage } from "./routes/auth/email-link-sent.tsx";
import { LogInPage } from "./routes/auth/log-in.tsx";
import { SignUpPage } from "./routes/auth/sign-up.tsx";
import { CalendarSlotRatingPage } from "./routes/calendar-slot-rating.tsx";
import { CallRecordingSnippetPage } from "./routes/call-recording-snippets/[callRecordingSnippetId]/index.tsx";
import { SharedRecordingPage } from "./routes/call-recordings/[callRecordingId]/index.tsx";
import { CompleteProfilePage } from "./routes/complete-profile.tsx";
import { EmailUnsubscribePage } from "./routes/email-unsubscribe.tsx";
import { ErrorPage } from "./routes/error-page.tsx";
import { HomePage } from "./routes/home.tsx";
import { AcceptInvitePage } from "./routes/invites/[inviteId]/accept.tsx";
import { MeetingAssistantVetoPage } from "./routes/meeting-assistant-veto.tsx";
import { OauthRedirectPage } from "./routes/oauth/[integration]/index.tsx";
import { OauthProviderRedirectPage } from "./routes/oauth-provider/[provider]/index.tsx";
import { CheckoutSessionPage } from "./routes/org/[organisationId]/checkout-session/index.tsx";
import { CreateSubscriptionPage } from "./routes/org/[organisationId]/create-subscription.tsx";
import { CallRecordingPage } from "./routes/org/[organisationId]/meeting-assistant/call-recordings/[callRecordingId]/index.tsx";
import { MeetingAssistantPage } from "./routes/org/[organisationId]/meeting-assistant/index.tsx";
import { RewardsPage } from "./routes/org/[organisationId]/rewards.tsx";
import { SettingsPage } from "./routes/org/[organisationId]/settings/index.tsx";
import { CreateOrganisationPage } from "./routes/org/create.tsx";
import { ListOrganisationsPage } from "./routes/org/index.tsx";
import { PrivacyPolicyPage } from "./routes/privacy-policy.tsx";
import { ProfilePage } from "./routes/profile.tsx";
import { RecallZoomRedirectPage } from "./routes/recall-zoom-redirect.tsx";
import { Root } from "./routes/root.tsx";
import { SandboxPage } from "./routes/sandbox.tsx";
import { AcceptOrganisationInvitePage } from "./routes/team-invites/[organisationInviteId]/accept.tsx";
import { ZoomDocumentation } from "./routes/zoom-documentation.tsx";
import * as Sentry from "@sentry/react";
import { InviteTeamPage } from "./routes/org/[organisationId]/invite-team.tsx";
import { MeetingSummaryRatingPage } from "./routes/meeting-summary-rating.tsx";
import { StopMeetingFailureNotificationsPage } from "./routes/org/[organisationId]/stop-meeting-recording-failure-notifications.tsx";
import { SchedulingRequestProvider } from "./context/SchedulingContext/SchedulingRequestContext.tsx";
import { SchedulingPage } from "./routes/scheduling/[schedulingRequestId]/index.tsx";
import { SchedulingRequestGuard } from "./context/SchedulingContext/guards/SchedulingRequestGuard.tsx";
import { AdminLoginPage } from "./routes/admin-login.tsx";

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <HomePage /> },
      { path: "sandbox321", element: <SandboxPage /> },
      { path: "privacy-policy", element: <PrivacyPolicyPage /> },
      { path: "recall-zoom-redirect", element: <RecallZoomRedirectPage /> },
      { path: "zoom-documentation", element: <ZoomDocumentation /> },
      { path: "email-unsubscribe", element: <EmailUnsubscribePage /> },
      { path: "meeting-assistant-veto", element: <MeetingAssistantVetoPage /> },
      { path: "calendar-slot-rating", element: <CalendarSlotRatingPage /> },
      { path: "meeting-summary-rating", element: <MeetingSummaryRatingPage /> },
      {
        path: "call-recordings/:callRecordingId",
        element: (
          <CallRecordingProvider>
            <CallRecordingGuard>
              <SharedRecordingPage />
            </CallRecordingGuard>
          </CallRecordingProvider>
        ),
      },
      {
        path: "call-recording-snippets/:callRecordingSnippetId",
        element: (
          <CallRecordingSnippetProvider>
            <CallRecordingSnippetGuard>
              <CallRecordingSnippetPage />
            </CallRecordingSnippetGuard>
          </CallRecordingSnippetProvider>
        ),
      },
      {
        path: "auth",
        element: (
          <UnauthGuard>
            <Outlet />
          </UnauthGuard>
        ),
        children: [
          { path: "sign-up", element: <SignUpPage /> },
          { path: "log-in", element: <LogInPage /> },
          { path: "email-link", element: <EmailLinkPage /> },
          { path: "email-link-sent", element: <EmailLinkSentPage /> },
        ],
      },

      {
        path: "oauth/:integration",
        element: (
          <AuthGuard>
            <UserGuard>
              <OauthRedirectPage />
            </UserGuard>
          </AuthGuard>
        ),
      },
      {
        path: "admin-login",
        element: (
          <AuthGuard>
            <UserGuard>
              <AdminLoginPage />
            </UserGuard>
          </AuthGuard>
        ),
      },
      {
        path: "oauth-provider/:provider",
        element: (
          <AuthGuard>
            <UserGuard>
              <OauthProviderRedirectPage />
            </UserGuard>
          </AuthGuard>
        ),
      },
      {
        path: "complete-profile",
        element: (
          <AuthGuard>
            <UserGuard allowMissingName>
              <CompleteProfilePage />
            </UserGuard>
          </AuthGuard>
        ),
      },
      {
        path: "profile",
        element: (
          <AuthGuard>
            <UserGuard>
              <ProfilePage />
            </UserGuard>
          </AuthGuard>
        ),
      },
      {
        path: "scheduling",
        children: [
          {
            path: ":schedulingRequestId",
            element: (
              <SchedulingRequestProvider>
                <SchedulingRequestGuard>
                  <SchedulingPage />
                </SchedulingRequestGuard>
              </SchedulingRequestProvider>
            ),
          },
        ],
      },
      {
        path: "invites/:inviteId/accept",
        element: (
          <AuthGuard>
            <UserGuard>
              <AcceptInvitePage />
            </UserGuard>
          </AuthGuard>
        ),
      },
      {
        path: "team-invites/:organisationInviteId/accept",
        element: (
          <AuthGuard>
            <UserGuard>
              <AcceptOrganisationInvitePage />
            </UserGuard>
          </AuthGuard>
        ),
      },
      {
        path: "org",
        element: (
          <AuthGuard>
            <UserGuard>
              <MembershipsGuard>
                <Outlet />
              </MembershipsGuard>
            </UserGuard>
          </AuthGuard>
        ),
        children: [
          { index: true, element: <ListOrganisationsPage /> },
          { path: "create", element: <CreateOrganisationPage /> },
          {
            path: ":organisationId",
            element: (
              <OrganisationGuard>
                <div className="flex h-full">
                  <SideBar />
                  <div className="h-full flex-grow overflow-scroll pt-8">
                    <ConnectionsGuard>
                      <Outlet />
                    </ConnectionsGuard>
                  </div>
                </div>
              </OrganisationGuard>
            ),
            children: [
              { index: true, element: <SettingsPage /> },
              {
                path: "create-subscription",
                element: <CreateSubscriptionPage />,
              },
              { path: "settings", element: <SettingsPage /> },
              { path: "invite-team", element: <InviteTeamPage /> },
              {
                path: "stop-meeting-recording-failure-notifications",
                element: <StopMeetingFailureNotificationsPage />,
              },
              {
                path: "meeting-assistant",
                element: (
                  <CalendarEventsProvider>
                    <CallRecordingsProvider>
                      <CalendarEventsGuard>
                        <CallRecordingsGuard>
                          <Outlet />
                        </CallRecordingsGuard>
                      </CalendarEventsGuard>
                    </CallRecordingsProvider>
                  </CalendarEventsProvider>
                ),
                children: [
                  { index: true, element: <MeetingAssistantPage /> },
                  {
                    path: "call-recordings/:callRecordingId",
                    element: (
                      <CallRecordingProvider>
                        <CallRecordingGuard>
                          <Outlet />
                        </CallRecordingGuard>
                      </CallRecordingProvider>
                    ),
                    children: [{ index: true, element: <CallRecordingPage /> }],
                  },
                ],
              },
              { path: "rewards", element: <RewardsPage /> },
              { path: "checkout-session", element: <CheckoutSessionPage /> },
            ],
          },
        ],
      },
    ],
  },
]);
