import { BillingCycle, constructUrl, getIsServiceTypeOrgLevel, SubscriptionPlanType } from "@fyxer-ai/shared";
import { toHeaderCase, toTextCase } from "js-convert-case";
import { HelpCircle } from "lucide-react";
import { ReactNode, useState } from "react";

import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useOrganisation } from "@/context/OrganisationContext/state/useOrganisation";
import { unwrap } from "@/lib/firebase/unwrap";

import { ConnectAnotherIntegrationRow } from "./ConnectAnotherIntegrationRow";
import { ConnectIntegrationForFirstTimeRow } from "./ConnectIntegrationForFirstTimeRow";
import { ConnectionRow } from "./ConnectionRow";
import { integrationData } from "./integrationData";
import { ServiceTypeDatum } from "./serviceTypeData";
import { useOauthRedirect } from "./useOauthRedirect";
import { EventButton, useAnalytics, useFeatureFlag } from "@/hooks/useAnalytics";
import { useComplexNavigate } from "@/hooks/useComplexNavigate";
import { useClickProps } from "@/hooks/useClickProps";
import { useApi } from "@/hooks/useApi";
import { toast } from "@/components/ui/use-toast";

export const ServiceTypeRow = (serviceTypeDatum: ServiceTypeDatum) => {
  const { serviceType, status, strapline, detail } = serviceTypeDatum;
  const {
    connections,
    organisationId,
    planData: { planType },
    memberships,
    subscription,
  } = useOrganisation();
  const api = useApi();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { logButtonPress } = useAnalytics();
  const gateConnectionsExp = useFeatureFlag("gate-more-connections");
  const { handleOauthRedirect, redirectingIntegration } = useOauthRedirect();
  const connectionsForServiceType = connections.filter(unwrap((connection) => connection.serviceType === serviceType));
  const hasConnection = connectionsForServiceType.length > 0;
  const integrationDataForServiceType = integrationData.filter(
    (integration) => integration.serviceType === serviceType,
  );

  const isStandard = planType === SubscriptionPlanType.STANDARD;
  const isFreePlan = planType === "FREE";
  const eligible = isStandard || isFreePlan;

  const hasNoPlan = eligible && gateConnectionsExp.value() === "test" ? eligible : isFreePlan;

  const navigate = useComplexNavigate();

  const handleRedirectToBilling = () => {
    logButtonPress(EventButton.SELECT_PLAN, { planName: SubscriptionPlanType.PRO });

    navigate(
      constructUrl({
        path: `/org/${organisationId}/create-subscription`,
        search: {
          billingCycle: BillingCycle.ANNUALLY,
          seatCount: memberships.length,
          planType: SubscriptionPlanType.PRO,
        },
      }),
    );
  };

  const clickProps = useClickProps({
    onClick: async () => {
      if (subscription) {
        logButtonPress(EventButton.UPGRADE_TO_PRO, {
          organisationId,
          subscriptionId: subscription?.id,
        });
        await api.organisations
          .id(organisationId)
          .subscriptions.id(subscription.id)
          .update({
            billingCycle: BillingCycle.ANNUALLY,
            planType: SubscriptionPlanType.PRO,
            seatCount: memberships.length ?? 1,
          });

        setIsDialogOpen(false);

        toast({
          title: `Upgrade successful`,
          description: `Your team is now on the Pro Plan!`,
        });
      }
    },
    buttonText: "Upgrade",
  });

  const ConfirmDialog = ({ trigger }: { trigger: ReactNode }) => (
    <Dialog open={isDialogOpen} onOpenChange={(open) => setIsDialogOpen(open)}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Upgrade to the Pro plan</DialogTitle>
          <DialogDescription>
            By upgrading, you'll be charged $50/user/seat rather than $30/user/seat. You'll stil get a discount if
            paying annually.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button variant="secondary" onClick={() => setIsDialogOpen(false)}>
            Cancel
          </Button>
          <Button {...clickProps} />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );

  return (
    <Card key={`service-type-row-${serviceType}`}>
      <div className="flex w-full items-center gap-x-4 p-6">
        <CardHeader className="flex-grow p-0">
          <CardTitle className="text-lg">{toHeaderCase(serviceType)}</CardTitle>
          <CardDescription>
            {strapline}
            {getIsServiceTypeOrgLevel(serviceType) ? (
              <>
                {" "}
                <b>You'll need to be an admin to connect this.</b>
              </>
            ) : null}
          </CardDescription>
        </CardHeader>
        {status === "BETA" ? <Badge>Beta</Badge> : null}
        <Dialog>
          <DialogTrigger>
            <Button variant="ghost" size="icon" className="flex-shrink-0">
              <HelpCircle className="h-4 w-4" />
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>How the {toTextCase(serviceType)} integration works</DialogTitle>
            </DialogHeader>
            <div className="text-sm">{detail()}</div>
          </DialogContent>
        </Dialog>
      </div>
      <CardContent>
        {hasConnection ? (
          <div className="space-y-4">
            {connectionsForServiceType.map((connection) => (
              <ConnectionRow connection={connection} />
            ))}
          </div>
        ) : (
          <div className="flex gap-x-4">
            {integrationDataForServiceType.map((integrationDatum) => (
              <ConnectIntegrationForFirstTimeRow
                {...{
                  serviceTypeDatum,
                  integrationDatum,
                  hasNoPlan,
                  redirectingIntegration,
                  handleRedirect: () =>
                    handleOauthRedirect({
                      integration: integrationDatum.integration,
                      organisationId,
                      type: "integration",
                    }),
                }}
              />
            ))}
          </div>
        )}
      </CardContent>
      {hasConnection ? (
        <CardFooter>
          {hasNoPlan ? (
            gateConnectionsExp.value() === "test" ? (
              <p className="text-sm">
                {!subscription ? (
                  <span
                    onClick={handleRedirectToBilling}
                    role="button"
                    className="cursor-pointer text-sm font-bold text-purple-700 underline"
                  >
                    Upgrade to Pro
                  </span>
                ) : (
                  <ConfirmDialog
                    trigger={
                      <span role="button" className="cursor-pointer text-sm font-bold text-purple-700 underline">
                        Upgrade to Pro
                      </span>
                    }
                  />
                )}{" "}
                to connect another
              </p>
            ) : (
              <p>Subscribe to connect another</p>
            )
          ) : (
            <>
              <Dialog>
                <DialogTrigger asChild>
                  <Button variant="secondary">Connect another</Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Connect your {toTextCase(serviceType)}</DialogTitle>
                    <DialogDescription>{strapline}</DialogDescription>
                  </DialogHeader>
                  <div>
                    {integrationDataForServiceType.map((integrationDatum) => {
                      const { integration } = integrationDatum;
                      return (
                        <ConnectAnotherIntegrationRow
                          {...{
                            integrationDatum,
                            redirectingIntegration,
                            handleRedirect: () =>
                              handleOauthRedirect({ integration, organisationId, type: "integration" }),
                          }}
                        />
                      );
                    })}
                  </div>
                </DialogContent>
              </Dialog>
              <Button
                variant="link"
                className="ml-2"
                onClick={() => {
                  navigate({ path: "", hash: { tab: "configuration" } });
                }}
              >
                Manage preferences
              </Button>
            </>
          )}
        </CardFooter>
      ) : null}
    </Card>
  );
};
