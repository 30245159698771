import { EventPage, useLogPageView } from "@/hooks/useAnalytics";
import { OAuthRedirect, useExchangeCode } from "@/routes/oauth-provider/[provider]";

export const OauthRedirectPage = () => {
  const { connection, orgId, authError, isPending } = useExchangeCode({ type: "integration" });

  useLogPageView(EventPage.OAUTH, { integration: connection });

  return <OAuthRedirect {...{ connection, orgId, authError, isPending }} />;
};
