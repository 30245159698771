import { constructUrl, OauthIntegration, OauthProvider, parseCustomEmailDomain, parseFullName } from "@fyxer-ai/shared";
import { AxiosError } from "axios";
import { useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import { SpinnerPage } from "@/components/layout/SpinnerPage";
import { EventName, EventPage, useAnalytics, useLogPageView } from "@/hooks/useAnalytics";
import { useApi } from "@/hooks/useApi";
import { useQueryParams } from "@/hooks/useQueryParams";
import { LocalStorageStringKey, storage } from "@/lib/storage";
import OAuthFeedback from "@/components/oAuthFeedback";
import { useUpdateState } from "@/hooks/useUpdateState";
import { pushToDataLayer } from "@/lib/pushToDataLayer";
import { useUser } from "@/context/BaseContext/state/useUser";

export function getErrorMessage({ error, error_description, state, provider }: Record<string, string | undefined>) {
  if (error || error_description) {
    return `Error: ${error_description || error}`;
  } else if (storage.local.string(LocalStorageStringKey.OAUTH_STATE_ID).get() !== state) {
    return `State does not match the state returned from ${provider?.split("-").join(" ") ?? "connection"}`;
  }
  return undefined;
}

export function useAccountConnected() {
  const { user, userId } = useUser();

  return {
    track(integration: string | undefined) {
      const name = parseFullName(user.name);

      pushToDataLayer({
        event: "Account Connected",
        user_props: {
          integration,
          email: user.email,
          external_id: userId,
          first_name: name.firstName,
          last_name: name.lastName,
          is_work_email: !!parseCustomEmailDomain(user.email),
        },
      });
    },
  };
}

export function useExchangeCode({ type }: { type: "provider" | "integration" }) {
  const params = useParams();
  const connection = params[type];
  const api = useApi();
  const { code, state, error, error_description } = useQueryParams();
  const accountConnect = useAccountConnected();
  const { logEvent } = useAnalytics();

  const [{ orgId, authError }, updateState] = useUpdateState<{
    orgId: string | undefined;
    authError: string | undefined;
  }>({
    orgId: undefined,
    authError: undefined,
  });

  const exchangeAuthCode = useMutation({
    mutationFn: () => {
      if (!code || !state)
        return Promise.reject(
          new Error(
            "We didn't receive a successful connection response. You may have cancelled the connection flow, or been denied access.",
          ),
        );

      if (!connection) return Promise.reject(new Error("This isn't a valid connection URL"));

      if (type === "provider" && !(Object.values(OauthProvider) as string[]).includes(connection))
        return Promise.reject(new Error(`The connection provider in the URL (${connection}) isn't recognised.`));

      if (type === "integration" && !(Object.values(OauthIntegration) as string[]).includes(connection))
        return Promise.reject(new Error(`The connection type in the URL (${connection}) isn't recognised.`));

      const sharedProps = { code, state };

      switch (type) {
        case "provider":
          return api.oauth.exchangeAuthCode({
            type: "provider",
            provider: connection as OauthProvider,
            ...sharedProps,
          });
        case "integration":
          return api.oauth.exchangeAuthCode({
            type: "integration",
            integration: connection as OauthIntegration,
            ...sharedProps,
          });
        default:
          return Promise.reject(new Error("Incorrect exchange option of " + connection));
      }
    },
    onSuccess: (orgIdResult) => {
      updateState({ orgId: orgIdResult });
      logEvent(EventName.COMPLETE_OAUTH_CONNECTION, { [type]: connection, organisationId: orgIdResult });
      accountConnect.track(connection);
    },
    onError: (err) => {
      const constructedErrorMessage = getErrorMessage({
        code,
        state,
        error,
        error_description,
        [type]: connection,
      });
      if (constructedErrorMessage) {
        updateState({ authError: constructedErrorMessage });
      } else if (err instanceof AxiosError) {
        updateState({ authError: err.response?.data?.message ?? err.message });
      } else {
        updateState({ authError: (err as { message: string }).message });
      }
    },
  });

  useEffect(() => {
    exchangeAuthCode.mutate();
    // eslint-disable-next-line
  }, [exchangeAuthCode.mutate]);

  return { connection: String(connection), orgId, authError, isPending: exchangeAuthCode.isPending };
}

export const OAuthRedirect = ({
  orgId,
  authError,
  connection,
  isPending,
}: {
  orgId?: string;
  authError?: string;
  connection: string;
  isPending: boolean;
}) => {
  if (orgId) {
    return (
      <Navigate
        to={constructUrl({
          path: `/org/${orgId}/settings`,
          hash: { tab: "integrations" },
        })}
      />
    );
  }

  if (isPending) return <SpinnerPage />;

  return <OAuthFeedback error={authError} connection={connection} />;
};

export const OauthProviderRedirectPage = () => {
  const { connection, orgId, authError, isPending } = useExchangeCode({ type: "provider" });

  useLogPageView(EventPage.OAUTH_PROVIDER, { provider: connection });

  return <OAuthRedirect {...{ connection, orgId, authError, isPending }} />;
};
