import {
  BillingCycle,
  deepModify,
  Environment,
  isIsoDateString,
  OauthIntegration,
  OauthProvider,
  OrganisationRole,
  SubscriptionPlanType,
  trimObjectToLength,
} from "@fyxer-ai/shared";
import axios, { AxiosError } from "axios";
import { parseISO } from "date-fns";
import { z } from "zod";

import { useAppCheckToken } from "@/context/BaseContext/state/useAppCheckToken";
import { useAuth } from "@/context/BaseContext/state/useAuth";
import { config } from "@/lib/config";
import { getEnvironment } from "@/lib/getEnvironment";

import { EventName, useAnalytics } from "./useAnalytics";

const useClient = () => {
  const { appCheckToken } = useAppCheckToken();
  const { authIdToken } = useAuth();
  const { logEvent } = useAnalytics();

  const baseUrl = `${getEnvironment() === Environment.LOCAL ? config().SERVER_URL : config().CLIENT_URL}/api`;

  const client = axios.create({
    baseURL: baseUrl,
    headers: {
      ...(authIdToken ? { Authorization: `Bearer ${authIdToken}` } : {}),
      "X-Firebase-AppCheck": appCheckToken,
    },
  });

  client.interceptors.request.use((request) => {
    const { baseURL, url, method, headers, data, params } = request;
    logEvent(EventName.MAKE_API_CALL, {
      request: {
        url: `${baseURL ?? ""}${url}`,
        method: method ?? "",
        headers: trimObjectToLength(headers),
        params: trimObjectToLength(params),
        data: trimObjectToLength(data),
      },
    });
    return request;
  });

  client.interceptors.response.use((response) => {
    const data = deepModify(response.data, {
      string: (value) => (isIsoDateString(value) ? parseISO(value) : value),
    });
    response.data = data;
    return response;
  });

  return client;
};

export const useApi = () => {
  const client = useClient();

  const url = new URL(window.location.href);
  const base = `${url.protocol}//${url.host}`;

  return {
    adminLogin: async ({
      requestedUserEmail,
      email,
      password,
    }: {
      requestedUserEmail: string;
      email: string;
      password: string;
    }) => {
      const res = await client.post<{ token: string }>(`/admin-login`, {
        requestedUserEmail,
        adminLoginCredentials: { email, password },
      });
      return res.data.token;
    },
    organisations: {
      id: (organisationId: string) => ({
        invites: {
          send: async (
            invites: {
              role: OrganisationRole;
              email: string;
            }[],
            options?: {
              referrer?: string;
            },
          ) =>
            client.post(`/organisations/${organisationId}/invites`, {
              inviteItems: invites,
              referrer: options?.referrer,
              base,
            }),
          recommend: async () => {
            const res = await client.get<{ emails: string[] }>(`/organisations/${organisationId}/invites/recommend`);
            return res.data.emails;
          },
        },
        organisationInvites: {
          create: async () => {
            const res = await client.post<{ organisationInviteId: string }>(
              `/organisations/${organisationId}/organisation-invites`,
            );
            const { organisationInviteId } = res.data;
            const link = `${base}/team-invites/${organisationInviteId}/accept`;
            return link;
          },
        },
        checkoutSessions: {
          create: async (data: {
            seatCount: number;
            billingCycle: BillingCycle;
            planType: SubscriptionPlanType;
            referralId?: string;
          }) => {
            const res = await client.post<{ checkoutSessionUrl: string }>(
              `/organisations/${organisationId}/checkout-sessions`,
              data,
            );
            return res.data.checkoutSessionUrl;
          },
          id: (checkoutSessionId: string) => ({
            cancel: async () =>
              client.delete(`/organisations/${organisationId}/checkout-sessions/${checkoutSessionId}`),
          }),
        },
        portalSessions: {
          create: async () => {
            const res = await client.post<{ url: string }>(`/organisations/${organisationId}/portal-sessions`);
            return res.data.url;
          },
        },
        subscriptions: {
          id: (subscriptionId: string) => ({
            update: async (data: { billingCycle: BillingCycle; planType: SubscriptionPlanType; seatCount: number }) =>
              client.put(`/organisations/${organisationId}/subscriptions/${subscriptionId}`, data),
            cancel: async () => client.delete(`/organisations/${organisationId}/subscriptions/${subscriptionId}`),
            getBillingPeriodEndDate: async () => {
              const res = await client.get<{ billingPeriodEndDate: Date }>(
                `/organisations/${organisationId}/subscriptions/${subscriptionId}/billing-period-end`,
              );
              return res.data.billingPeriodEndDate;
            },
          }),
        },
      }),
      joinIfNeeded: async () => {
        const res = await client.post<{ organisationId?: string }>("/organisations/join-if-needed");
        return res.data.organisationId;
      },
      create: async (data: { name: string }) => {
        const res = await client.post<{ organisationId: string }>("/organisations", data);
        return res.data.organisationId;
      },
    },
    callRecordings: {
      id: (callRecordingId: string) => ({
        delete: async () => {
          await client.delete(`/call-recordings/${callRecordingId}`);
        },
        sendAccessRequest: async () => {
          await client.post(`/call-recordings/${callRecordingId}/access-request`);
        },
      }),
    },
    callRecordingSnippets: {
      id: (callRecordingSnippetId: string) => ({
        sendAccessRequest: async () => {
          await client.post(`/call-recording-snippets/${callRecordingSnippetId}/access-request`);
        },
      }),
    },
    invites: {
      id: (inviteId: string) => ({
        accept: async () => {
          const res = await client.put<{ organisationId: string }>(`/invites/${inviteId}/accept`);
          return res.data.organisationId;
        },
      }),
    },
    organisationInvites: {
      id: (organisationInviteId: string) => ({
        accept: async () => {
          const res = await client.put<{ organisationId: string }>(
            `/organisation-invites/${organisationInviteId}/accept`,
          );
          return res.data.organisationId;
        },
      }),
    },
    account: {
      delete: async () => {
        await client.delete("/account");
      },
    },
    oauth: {
      exchangeAuthCode: async ({
        code,
        state,
        ...rest
      }: { code: string; state: string } & (
        | { type: "provider"; provider: OauthProvider }
        | { type: "integration"; integration: OauthIntegration }
      )) => {
        const res = await client.post<{ organisationId: string }>(`/oauth/exchange-code`, {
          code,
          state,
          base,
          ...rest,
        });
        return res.data.organisationId;
      },
      getAuthUrl: async ({
        organisationId,
        stateId,
        ...rest
      }: {
        organisationId: string;
        stateId: string;
      } & ({ type: "provider"; provider: OauthProvider } | { type: "integration"; integration: OauthIntegration })) => {
        const url = new URL(window.location.href);
        const base = `${url.protocol}//${url.host}`;
        const res = await client.post<{ authUrl: string }>(`/oauth/auth-url`, {
          organisationId,
          stateId,
          base,
          ...rest,
        });
        return res.data.authUrl;
      },
    },
    recall: {
      getZoomAuthorizationUrl: async ({ organisationId, stateId }: { organisationId: string; stateId: string }) => {
        const res = await client.post<{ authorizationUrl: string }>("/recall/zoom-authorization-url", {
          organisationId,
          stateId,
        });
        return res.data.authorizationUrl;
      },
      registerZoomOauthCredentials: async (args: { code: string; state: string }) => {
        try {
          const res = await client.post<{ organisationId: string }>("/recall/register-connection", args);
          const { organisationId } = res.data;

          return {
            success: true,
            organisationId,
          };
        } catch (err) {
          if (!(err instanceof AxiosError)) throw err;
          const zoomConflictSchema = z.object({
            message: z.enum(["CONFLICTING_ZOOM_ACCOUNT_ID", "CONFLICTING_ZOOM_USER_ID"]),
            organisationId: z.string(),
          });

          try {
            const { organisationId } = zoomConflictSchema.parse(err.response?.data);
            return {
              success: false,
              organisationId,
            };
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
          } catch (errorParsingError) {
            throw err;
          }
        }
      },
    },
  };
};
