"use client";

import { Plus, X } from "lucide-react";
import { ReactNode } from "react";
import {
  FieldArray,
  FieldArrayPath,
  FieldValues,
  useFieldArray,
  UseFormRegister,
  UseFormReturn,
} from "react-hook-form";

import { Button } from "@/components/ui/button";

export const ArrayField = <
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
>({
  form,
  name,
  minLength = 0,
  maxLength = 1000,
  generateEmptyDatum,
  render,
  addButtonLabel,
  areMinElementsFixed = false,
  shouldHideRemoveButton = () => false,
}: {
  form: UseFormReturn<TFieldValues>;
  name: TFieldArrayName;
  minLength?: number;
  maxLength?: number;
  generateEmptyDatum: (index: number) => FieldArray<TFieldValues, TFieldArrayName>;
  render: (index: number, register: UseFormRegister<TFieldValues>) => ReactNode;
  addButtonLabel?: string;
  areMinElementsFixed?: boolean;
  shouldHideRemoveButton?: (index: number) => boolean;
}) => {
  const { register, control } = form;
  const { fields, append, remove } = useFieldArray({
    control,
    name,
    rules: {
      minLength,
      maxLength,
    },
  });

  return (
    <div className="space-y-4">
      <div className="max-h-[400px] space-y-4 overflow-scroll">
        {fields.map((field, index) => (
          <div key={field.id} className="flex items-center gap-x-2">
            {render(index, register)}
            {fields.length > minLength &&
            !shouldHideRemoveButton(index) &&
            (!areMinElementsFixed || index > minLength - 1) ? (
              <Button variant="secondary" onClick={() => remove(index)}>
                <X size={16} />
              </Button>
            ) : null}
          </div>
        ))}
      </div>

      {fields.length < maxLength ? (
        <Button onClick={() => append(generateEmptyDatum(fields.length))} type="button" variant="secondary">
          <Plus size={16} /> {addButtonLabel}
        </Button>
      ) : null}
    </div>
  );
};
