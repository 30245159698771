import {
  getCompanyName,
  getFaviconSrc,
  getWhitelabelProviderFromPageUrl,
  WhitelabelProvider,
} from "@/lib/getWhitelabelProvider";
import { useEffect } from "react";

const setFavicon = (url: string) => {
  const link: HTMLLinkElement | null = document.querySelector("link[rel*='icon']");
  if (link) {
    link.href = url;
  } else {
    const newLink = document.createElement("link");
    newLink.rel = "icon";
    newLink.href = url;
    document.head.appendChild(newLink);
  }
};

export const useSetTitleAndFavicon = () => {
  useEffect(() => {
    if (getWhitelabelProviderFromPageUrl() === WhitelabelProvider.FYXER_AI) return;

    document.title = getCompanyName();
    setFavicon(getFaviconSrc());
  }, []);
};
