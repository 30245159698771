import { BillingCycle, constructUrl, range, SubscriptionPlanType } from "@fyxer-ai/shared";

import handshakeSrc from "@/assets/subscription-plan-icons/handshake.svg";
import lightningSrc from "@/assets/subscription-plan-icons/lightning.svg";
import rocketSrc from "@/assets/subscription-plan-icons/rocket.svg";
import { Button } from "@/components/ui/button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { EventButton, EventPage, useAnalytics, useLogPageView } from "@/hooks/useAnalytics";
import { useComplexNavigate } from "@/hooks/useComplexNavigate";
import { redirectExternally } from "@/lib/redirectExternally";
import { cn } from "@/lib/utils";
import { useOrganisation } from "@/context/OrganisationContext/state/useOrganisation";
import { toHeaderCase } from "js-convert-case";
import { useIsMobile } from "@/hooks/useIsMobile";

export const planTypes = [
  SubscriptionPlanType.STANDARD,
  SubscriptionPlanType.PRO,
  SubscriptionPlanType.ENTERPRISE,
] as const;
type PlanType = (typeof planTypes)[number];

type Color = "indigo" | "violet" | "purple" | "pink";

export const annualPlanDiscount = 11 / 12;

type NewDatum = {
  planType: PlanType;
  iconSrc: string;
  color: Color;
  priceMonthlyCents: number | undefined;
  buttonCta: string;
  data: (string | boolean)[];
};

export const newData: NewDatum[] = [
  {
    planType: SubscriptionPlanType.STANDARD,
    data: ["Pay and add your team.", "PRICE", "Chat support", "-", "-", "-", "-"],
    priceMonthlyCents: 3000,
    color: "purple",
    iconSrc: lightningSrc,
    buttonCta: "Choose",
  },
  {
    planType: SubscriptionPlanType.PRO,
    data: [
      "Pay and add your team.",
      "PRICE",
      "Account manager with 24/7 support",
      "Hubspot & Salesforce integration",
      "Add multiple inboxes and calendars for each user",
      "Custom branded Meeting Notetaker",
      "-",
    ],
    priceMonthlyCents: 5000,
    color: "indigo",
    iconSrc: rocketSrc,
    buttonCta: "Choose",
  },
  {
    planType: SubscriptionPlanType.ENTERPRISE,
    data: [
      "Talk to us about advanced features for bigger teams.",
      "PRICE",
      "Account manager with 24/7 support",
      "Hubspot & Salesforce integration",
      "Add multiple inboxes and calendars for each user",
      "Custom branded Meeting Notetaker",
      "SSO Login, Team Analytics, Advanced security controls",
    ],
    color: "pink",
    priceMonthlyCents: undefined,
    iconSrc: handshakeSrc,
    buttonCta: "Talk to us",
  },
];

const getColorData = (color: Color) => {
  switch (color) {
    case "indigo":
      return {
        bg: "bg-gradient-to-r from-indigo-100 to-indigo-50",
        text: "text-indigo-500",
      };
    case "violet":
      return {
        bg: "bg-gradient-to-r from-violet-100 to-violet-50",
        text: "text-violet-500",
      };
    case "purple":
      return {
        bg: "bg-gradient-to-r from-purple-100 to-purple-50",
        text: "text-purple-500",
      };
    case "pink":
      return {
        bg: "bg-gradient-to-r from-pink-100 to-pink-50",
        text: "text-pink-500",
      };
  }
};

const commariseNumber = (n: number, decimalPlaces: number = 2, locale: string = "en-US"): string => {
  const options = {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  };
  return n.toLocaleString(locale, options);
};

export const getPriceCents = ({
  priceMonthlyCents,
  billingCycle,
}: {
  priceMonthlyCents: number;
  billingCycle: BillingCycle;
}) => Math.round(priceMonthlyCents * (billingCycle === BillingCycle.ANNUALLY ? annualPlanDiscount : 1));

export const getDisplayPrice = (priceCents: number, showCents = false) => {
  const price = priceCents / 100;

  if (showCents) {
    return `$${commariseNumber(price, 2)}`;
  }
  return `$${commariseNumber(price, 0)}`;
};

const displayBillingCycle = BillingCycle.ANNUALLY;

export const PricingView = () => {
  const { organisationId, memberships } = useOrganisation();
  const { logButtonPress } = useAnalytics();
  const isMobile = useIsMobile();
  useLogPageView(EventPage.PRICING, { organisationId });

  const navigate = useComplexNavigate();

  const handleSelectPlan = (planType: PlanType) => () => {
    logButtonPress(EventButton.SELECT_PLAN, { planName: planType });
    if (planType === SubscriptionPlanType.ENTERPRISE)
      return redirectExternally("https://cal.com/forms/26b045cc-f0b2-4987-a8ea-2fad13840543");

    navigate(
      constructUrl({
        path: `/org/${organisationId}/create-subscription`,
        search: {
          billingCycle: displayBillingCycle,
          seatCount: memberships.length,
          planType,
        },
      }),
    );
  };

  if (isMobile)
    return (
      <div className="space-y-8">
        {newData.map((datum) => (
          <Table wrapperClassName="border border-black rounded-3xl">
            <TableHeader>
              <TableRow className="hover:bg-clear border-b border-black">
                <TableHead
                  key={datum.planType}
                  className="border-l border-r border-black first:border-l-0 last:border-r-0"
                >
                  <div className="flex flex-col items-center space-y-4 py-8">
                    <img src={datum.iconSrc} className="h-10" />
                    <h3>{toHeaderCase(datum.planType)}</h3>
                  </div>
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {datum.data
                .filter((d) => d !== "-")
                .map((text, i) => (
                  <TableRow key={i} className="border-b border-black">
                    <TableCell
                      key={datum.planType}
                      className={cn(
                        getColorData(datum.color).bg,
                        getColorData(datum.color).text,
                        "border-l border-r border-black p-4 text-center first:border-l-0 last:border-r-0",
                      )}
                    >
                      {text === "PRICE"
                        ? datum.priceMonthlyCents
                          ? getDisplayPrice(
                              getPriceCents({
                                billingCycle: displayBillingCycle,
                                priceMonthlyCents: datum.priceMonthlyCents,
                              }),
                            ).toString() + "/user/month"
                          : "Tailored pricing"
                        : text}
                    </TableCell>
                  </TableRow>
                ))}
              <TableRow>
                <TableCell
                  className={cn(
                    getColorData(datum.color).bg,
                    getColorData(datum.color).text,
                    "border-l border-r border-black p-4 text-center first:border-l-0 last:border-r-0",
                  )}
                >
                  <Button
                    className="w-full p-4 text-black"
                    variant="outline"
                    onClick={handleSelectPlan(datum.planType)}
                  >
                    {datum.buttonCta}
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ))}
      </div>
    );

  return (
    <div className="space-y-8 p-4">
      <div className="m-auto max-w-5xl space-y-8">
        <Table className="table-fixed" wrapperClassName="border border-black rounded-3xl">
          <TableHeader>
            <TableRow className="hover:bg-clear border-b border-black">
              {newData.map((datum) => (
                <TableHead
                  key={datum.planType}
                  className="border-l border-r border-black first:border-l-0 last:border-r-0"
                >
                  <div className="flex flex-col items-center space-y-4 py-8">
                    <img src={datum.iconSrc} className="h-10" />
                    <h3>{toHeaderCase(datum.planType)}</h3>
                  </div>
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {range(0, newData[0].data.length).map((i) => (
              <TableRow key={i} className="border-b border-black">
                {newData.map((datum) => (
                  <TableCell
                    key={datum.planType}
                    className={cn(
                      getColorData(datum.color).bg,
                      getColorData(datum.color).text,
                      "border-l border-r border-black p-4 text-center first:border-l-0 last:border-r-0",
                    )}
                  >
                    {datum.data[i] === "PRICE"
                      ? datum.priceMonthlyCents
                        ? getDisplayPrice(
                            getPriceCents({
                              billingCycle: displayBillingCycle,
                              priceMonthlyCents: datum.priceMonthlyCents,
                            }),
                          ).toString() + "/user/month"
                        : "Tailored pricing"
                      : datum.data[i]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
            <TableRow>
              {newData.map((datum) => (
                <TableCell
                  className={cn(
                    getColorData(datum.color).bg,
                    getColorData(datum.color).text,
                    "border-l border-r border-black p-8 text-center first:border-l-0 last:border-r-0 max-sm:px-2",
                  )}
                >
                  <Button
                    className="w-full p-4 text-black"
                    variant="outline"
                    onClick={handleSelectPlan(datum.planType)}
                  >
                    {datum.buttonCta}
                  </Button>
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
