import { Banknote, Building, LucideIcon, Menu, Phone, Plus, Settings, Video, X } from "lucide-react";
import { Link, NavLink, To, useParams } from "react-router-dom";

import { useMemberships } from "@/context/BaseContext/state/useMemberships";
import { useBase } from "@/context/BaseContext/state/useBase";
import { useIsMobile } from "@/hooks/useIsMobile";
import { useUpdateState } from "@/hooks/useUpdateState";
import { useWindowSize } from "@/hooks/useWindowSize";
import { EventButton, useAnalytics } from "@/hooks/useAnalytics";
import { cn } from "@/lib/utils";

import { Badge } from "../ui/badge";
import { Button } from "../ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { Skeleton } from "../ui/skeleton";

const SideBarLink = ({
  to,
  title,
  Icon,
  isExpanded,
  isBeta = false,
  newTab = false,
  onClick,
  closeSidebar,
}: {
  to: To;
  title: string;
  Icon: LucideIcon;
  isExpanded: boolean;
  isBeta?: boolean;
  newTab?: boolean;
  onClick?: () => void;
  closeSidebar: () => void;
}) => {
  const isMobile = useIsMobile();

  return (
    <NavLink
      to={to}
      {...(newTab ? { target: "_blank" } : {})}
      onClick={onClick || (isMobile ? closeSidebar : undefined)}
      className={({ isActive }) =>
        cn(
          "flex h-12 items-center gap-4 px-4 text-sm hover:opacity-60",
          isActive ? "bg-slate-200" : "",
          !isExpanded ? "justify-center" : "",
        )
      }
    >
      <Icon size={20} />
      {isExpanded ? title : null}
      {isBeta && isExpanded ? <Badge className="text-[10px]">Beta</Badge> : null}
    </NavLink>
  );
};

interface Data {
  isExpanded: boolean;
}

export const SideBar = () => {
  const { organisationId } = useParams();
  const { enrichedData } = useBase();
  const { memberships } = useMemberships();
  const { logButtonPress } = useAnalytics();
  const activeMembership = memberships.find((m) => m.data().organisationId === organisationId);
  const activeMembershipData = activeMembership?.data();
  const { width } = useWindowSize();
  const isMobile = width < 640;

  const [state, updateState] = useUpdateState<Data>({
    isExpanded: !isMobile,
  });

  const orgSizeValidForCall = (enrichedData.value?.enrichedIndividualData?.org?.numberOfEmployees ?? 0) >= 15;
  const eligbleForCallOrDemo = orgSizeValidForCall;

  return (
    <>
      {isMobile ? (
        <Button
          size="icon"
          variant="secondary"
          className="absolute left-0 top-12 z-20 rounded-none rounded-br-md"
          onClick={() => updateState({ isExpanded: !state.isExpanded })}
        >
          {state.isExpanded ? <X /> : <Menu />}
        </Button>
      ) : null}
      <div
        className={cn(
          "flex flex-shrink-0 flex-col border-r-2 border-slate-100 bg-slate-50",
          state.isExpanded
            ? "max-sm:absolute max-sm:bottom-0 max-sm:left-0 max-sm:right-0 max-sm:top-12 max-sm:z-10 sm:w-64"
            : "hidden w-auto",
        )}
      >
        <div className="p-2 max-sm:pt-12">
          {activeMembershipData ? (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <div
                  className={cn(
                    "flex h-10 cursor-pointer items-center gap-2 rounded-md border-2 border-slate-100 bg-white",
                    state.isExpanded ? "px-2" : "justify-center",
                  )}
                >
                  {state.isExpanded ? <Building className="h-5 w-5" /> : null}
                  <h4>
                    {state.isExpanded
                      ? activeMembershipData.organisationName
                      : activeMembershipData.organisationName[0].toUpperCase()}
                  </h4>
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56">
                <DropdownMenuLabel>Your organisations</DropdownMenuLabel>
                {memberships.map((membership) => {
                  const { organisationId: mOrgId, organisationName } = membership.data();
                  return (
                    <Link to={`/org/${mOrgId}`} key={membership.id}>
                      <DropdownMenuItem className={mOrgId === organisationId ? "bg-slate-100" : "cursor-pointer"}>
                        <Building className="mr-2 h-4 w-4" />
                        {organisationName}
                      </DropdownMenuItem>
                    </Link>
                  );
                })}
                <DropdownMenuSeparator />
                <Link to="/org/create">
                  <DropdownMenuItem className="cursor-pointer">
                    <Plus className="mr-2 h-4 w-4" />
                    New organisation
                  </DropdownMenuItem>
                </Link>
              </DropdownMenuContent>
            </DropdownMenu>
          ) : (
            <Skeleton className="h-6" />
          )}
        </div>
        <SideBarLink
          to={`/org/${organisationId}/settings`}
          title="Settings"
          closeSidebar={() => updateState({ isExpanded: false })}
          Icon={Settings}
          isExpanded={state.isExpanded}
        />

        <SideBarLink
          to={`/org/${organisationId}/meeting-assistant`}
          title="Meeting notetaker"
          closeSidebar={() => updateState({ isExpanded: false })}
          Icon={Video}
          isExpanded={state.isExpanded}
        />

        {eligbleForCallOrDemo ? (
          <SideBarLink
            newTab
            to={`https://cal.com/forms/26b045cc-f0b2-4987-a8ea-2fad13840543`}
            onClick={() => logButtonPress(EventButton.BOOK_A_CALL_CLICK, { source: "sidebar" })}
            title="Speak to sales"
            closeSidebar={() => updateState({ isExpanded: false })}
            Icon={Phone}
            isExpanded={state.isExpanded}
          />
        ) : null}

        <SideBarLink
          to={`/org/${organisationId}/rewards`}
          title="Rewards"
          closeSidebar={() => updateState({ isExpanded: false })}
          Icon={Banknote}
          isExpanded={state.isExpanded}
        />

        <div className="flex-grow"></div>
      </div>
    </>
  );
};
