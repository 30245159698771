import { useEffect, useState } from "react";
import { date, OauthServiceType, SubscriptionStatus } from "@fyxer-ai/shared";
import { differenceInHours, formatDate, intervalToDuration } from "date-fns";
import pluralize from "pluralize";

import { Button } from "@/components/ui/button";
import { Card, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { useUser } from "@/context/BaseContext/state/useUser";
import { useOrganisation } from "@/context/OrganisationContext/state/useOrganisation";
import { EventButton, useAnalytics, useFeatureFlag } from "@/hooks/useAnalytics";
import { useComplexNavigate } from "@/hooks/useComplexNavigate";
import { unwrap } from "@/lib/firebase/unwrap";
import { useCheckout } from "@/components/FreeTrialPopup";

import { SettingsTabName } from ".";
import { PlanTypeSource } from "./tabs/billing/getPlanType";
import { getCompanyName } from "@/lib/getWhitelabelProvider";

const ConnectEmailCard = ({ tab }: { tab: string }) => {
  const navigate = useComplexNavigate();
  if (tab === "integrations") return null;
  return (
    <Card>
      <CardHeader>
        <CardTitle>Connect your email</CardTitle>
        <CardDescription>
          For {getCompanyName()} to start categorising your email and suggesting draft responses, we need to be
          connected to your Gmail or Outlook. You can connect on the Integrations tab.
        </CardDescription>
      </CardHeader>
      <CardFooter>
        <Button disabled={tab === "integrations"} onClick={() => navigate({ path: "", hash: { tab: "integrations" } })}>
          Connect my email
        </Button>
      </CardFooter>
    </Card>
  );
};

const StartSubscriptionCard = ({ tab, planTypeSource }: { tab: string; planTypeSource: PlanTypeSource }) => {
  const { freeTrialEndsAt } = useUser();
  const navigate = useComplexNavigate();
  const checkout = useCheckout();
  const [left, setLeft] = useState(
    intervalToDuration({
      start: Date.now(),
      end: freeTrialEndsAt,
    }),
  );
  const { logButtonPress } = useAnalytics();
  const bannerFlag = useFeatureFlag("upgrade-banner");

  useEffect(() => {
    let interval;

    interval = setInterval(() => {
      setLeft(
        intervalToDuration({
          start: Date.now(),
          end: freeTrialEndsAt,
        }),
      );
    }, 1000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  if (tab === "billing") return null;

  const hoursLeftInFreeTrial = differenceInHours(freeTrialEndsAt, date.now());
  const formattedTimeLeftInFreeTrial =
    hoursLeftInFreeTrial > 24
      ? `${pluralize("day", Math.round(hoursLeftInFreeTrial / 24), true)}`
      : `${pluralize("hour", hoursLeftInFreeTrial, true)}`;

  if (freeTrialEndsAt > new Date() && bannerFlag.value() === "test") {
    const isPriority = left.days === undefined;
    return (
      <Card className={`${isPriority ? "border-red-800" : "border-purple-800"}`}>
        <CardHeader>
          <CardTitle className={`mb-1 ${isPriority ? "text-red-800" : "text-gray-800"}`}>
            Your free trial expires in: {left.days ? `${left.days}d` : ""} {left.hours ? `${left.hours}h` : ""}{" "}
            {left.minutes ? `${left.minutes}m` : ""} {left.seconds}s
          </CardTitle>
          <CardDescription>
            To keep {getCompanyName()} connected to your tools, upgrade your account before your free trial ends on the{" "}
            {formatDate(freeTrialEndsAt, "do MMMM yyyy")}.
          </CardDescription>
        </CardHeader>
        <CardFooter>
          <div className="flex gap-x-2">
            <Button
              disabled={tab === "billing"}
              className={`${isPriority ? "bg-red-800 hover:bg-red-900" : ""}`}
              onClick={() => checkout.start("upgrade_banner", { trial_length: 0 })}
            >
              Upgrade now
            </Button>
            <Button
              variant="link"
              disabled={tab === "billing"}
              onClick={() => {
                logButtonPress(EventButton.UPGRADE, { location: "top_banner" });
                navigate({ path: "", hash: { tab: "billing" } });
              }}
            >
              View plans
            </Button>
          </div>
        </CardFooter>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>No subscription</CardTitle>
        <CardDescription>
          {planTypeSource === PlanTypeSource.FREE_TRIAL
            ? `You're on a free trial that expires in ${formattedTimeLeftInFreeTrial}. To continue using ${getCompanyName()}, upgrade to a paid plan.`
            : `Your free trial has expired. To continue using ${getCompanyName()}, upgrade to a paid plan.`}
        </CardDescription>
      </CardHeader>
      <CardFooter>
        <Button
          disabled={tab === "billing"}
          onClick={() => {
            logButtonPress(EventButton.UPGRADE, { location: "top_banner" });
            navigate({ path: "", hash: { tab: "billing" } });
          }}
        >
          Upgrade
        </Button>
      </CardFooter>
    </Card>
  );
};

const AddTeamMemberCard = ({ tab }: { tab: string }) => {
  const navigate = useComplexNavigate();
  if (tab === "team") return null;
  return (
    <Card>
      <CardHeader>
        <CardTitle>Add team members</CardTitle>
        <CardDescription>
          {getCompanyName()} is better with your teammates. You can add them to your organisation in a couple of clicks
          on the Team tab.
        </CardDescription>
      </CardHeader>
      <CardFooter>
        <Button
          disabled={tab === SettingsTabName.TEAM}
          onClick={() => {
            navigate({ path: "", hash: { tab: SettingsTabName.TEAM }, search: { shouldInvite: true } });
          }}
        >
          Add team members
        </Button>
      </CardFooter>
    </Card>
  );
};

const DelinquentCard = ({ tab }: { tab: string }) => {
  const { logButtonPress } = useAnalytics();
  const navigate = useComplexNavigate();
  if (tab === "billing") return null;

  return (
    <Card>
      <CardHeader>
        <CardTitle>Your behind on your payments</CardTitle>
        <CardDescription>
          Your subscription payment method has failed. To keep using {getCompanyName()}, update your payment method.
          Once we get a successful payment, we will resume your subscription.
        </CardDescription>
      </CardHeader>

      <CardFooter>
        <Button
          disabled={tab === "billing"}
          onClick={() => {
            logButtonPress(EventButton.UPGRADE, { location: "top_banner" });
            navigate({ path: "", hash: { tab: "billing" } });
          }}
        >
          Add a payment method
        </Button>
      </CardFooter>
    </Card>
  );
};

export const OnboardingCard = ({ tab }: { tab: string }) => {
  const { firstSignUpDate } = useUser();
  const {
    planData: { planTypeSource, planType },
    connections,
    memberships,
    subscription,
  } = useOrganisation();
  const needsToConnectEmail = !connections.some(
    unwrap((connection) => connection.serviceType === OauthServiceType.EMAIL),
  );

  const needsSubscription = planType === "FREE";
  const shouldSubscribeButDoesntNeedTo = planTypeSource === PlanTypeSource.FREE_TRIAL;
  const shouldAddTeamMember = memberships.length === 1;

  if (needsSubscription)
    return subscription?.data().status === SubscriptionStatus.DELINQUENT ? (
      <DelinquentCard tab={tab} />
    ) : (
      <StartSubscriptionCard {...{ tab, firstSignUpDate, planTypeSource }} />
    );
  if (needsToConnectEmail) return <ConnectEmailCard tab={tab} />;
  if (shouldSubscribeButDoesntNeedTo) return <StartSubscriptionCard {...{ tab, planTypeSource }} />;
  if (shouldAddTeamMember) return <AddTeamMemberCard tab={tab} />;

  return null;
};
